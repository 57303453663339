import "../../styles/Home/Contribution.scss";
import image from "../../images/contribution.png";
import image1 from "../../images/con1.png";
import image2 from "../../images/con2.png";
import image3 from "../../images/con3.png";

const Contribution = () => {
  return (
    <div className="contribution-back">
      <div className="contribution">
        <img src={image} className="image" />
        <div className="contents">
          <div className="subtitle">Contribution</div>
          <div className="title">Contributions to Ecosystem</div>
          <div className="text">
            Our contribution is rooted in a deep understanding of the challenges
            and opportunities that lie ahead. We envision a future where
            describe the positive changes or advancements your company aims to
            bring
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image1} className="icon"></img>
            </div>
            <div className="content-panel">
              <div className="title">Blockchain Explorer</div>
              <div className="text">
                We are operating a Cosmos blockchains explorer at CosmosRun.info
                for both mainnets and testnets. We must thank ping.pub because
                we have forked their github repo and using that for our explorer
                website.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image2} className="icon"></img>
            </div>
            <div className="content-panel">
              <div className="title">Youtube Channel</div>
              <div className="text">
                Our Youtube channel is focused on providing technical tutorials
                on installing and operate validator nodes for Cosmos based
                blockchains. Along with this, we also create many other
                blockchain based tutorials which are useful for users.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image3} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="title">Crosschain Bridge App</div>
              <div className="text">
                We have created a Crosschain Bridge app powered by Axelar where
                users can transfer many Assets from EVM chains such as Ethereum,
                Avalanche, Polygon, Fantom, etc to various Cosmos based chains
                such as Cosmos, Osmosis, Kujira, etc.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contribution;
