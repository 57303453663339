import Header from "./Header";
import Intro from "./Home/Intro";
import React from "react";
import Wallet from "./Home/Wallet";
import Stats from "./Home/Stats";
import Vision from "./Home/Vision";
import Mainnet from "./Home/Mainnet";
import Testnet from "./Home/Testnet";
import Contribution from "./Home/Contribution";
import Subscribe from "./Home/Subscribe";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <Header />
      <Intro />
      <Stats />
      <Wallet />
      <Vision />
      <Mainnet />
      <Testnet />
      <Contribution />
      <Footer />
    </>
  );
};

export default Home;
