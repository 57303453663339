import Header from "./Header";
import SecurityIntro from "./Security/SecurityIntro";
import Stats from "./Home/Stats";
import Measure from "./Security/Measure";
import Subscribe from "./Home/Subscribe";
import Footer from "./Footer";

const Security = () => {
  return (
    <>
      <Header />
      <SecurityIntro />
      <Stats />
      <Measure />
      <Footer />
    </>
  );
};

export default Security;
