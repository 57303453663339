import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import image from "../../images/terra.png";
import arrow from "../../images/arrow.jpg";
import sui from "../../images/item1.png";
import tenet from "../../images/item2.png";
import axelar from "../../images/item3.png";
import terraclassic from "../../images/item4.png";
import mars from "../../images/item5.png";
import kujira from "../../images/item6.png";
import stride from "../../images/item7.png";
import migaloo from "../../images/item8.png";
import planq from "../../images/item9.png";
import canto from "../../images/item10.png";
import acre from "../../images/item11.png";
import terra from "../../images/terra.png";
import oraichain from "../../images/item13.png";
import provenance from "../../images/item14.png";
import meme from "../../images/item15.png";
import injective from "../../images/injective.png";
import persistence from "../../images/persistence.png";
import sei from "../../images/sei.png";
import furya from "../../images/furya.png";
import cosmoshub from "../../images/cosmos.png";
import evmos from "../../images/evmos.png";
import chihuahua from "../../images/chihuahua.png";
import osmosis from "../../images/osmosis.png";
import juno from "../../images/juno.png";
import gravity from "../../images/gravity.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { snapshots } from '../../components/Chains/snapshots';
import { peersFurya } from '../../components/Peers/peersFurya';
import { Link } from "react-router-dom";

const ServiceFurya = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();
      setVersion(data[0]["furya"].version);
      setSize(data[0]["furya"].size)
      setBlock(data[0]["furya"].block);
      setTimestamp(data[0]["furya"].timestamp);
      setFile(data[0]["furya"].file);
      setLink(data[0]["furya"].link);
    }

    myFetch()
      .catch(console.error);  

  }, []);

  const [toolsMenu, setToolsMenu] = useState(true);
  const [isBackVisible, setBack] = useState(true);    
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const [isBelowVisible9, setBelowVisible9] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersFurya['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.furyad/config/config.toml`;


  const snapOne = `wget -O ` + file + ` ` + link;


  const snapTwo = `lz4 -c -d ` + file + ` | tar -x -C $HOME/.furyad`;

  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/furya_mainnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.furyad/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis/furya_mainnet/genesis.json --inet4-only
mv genesis.json $HOME/.furyad/config`;

 

const nodeInstallUser = `sudo apt update
sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y`;

const nodeInstallGo = `# Install Go
cd $HOME
curl https://dl.google.com/go/go1.20.5.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -
  
# Update environment variables to include go
cat <<'EOF' >>$HOME/.profile
export GOROOT=/usr/local/go
export GOPATH=$HOME/go
export GO111MODULE=on
export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
EOF
  
source $HOME/.profile
  
# check go version
go version`;


const nodeInstallNode = `# Install Furya node
git clone git clone https://github.com/furysport/furya furya
cd furya
git checkout `+ version +`
make install
cd
furyad version --long`;


const nodeInstallInitialize = `# Initialize Node
furyad init <moniker> --chain-id=furya-1

# Example:
furyad init Synergy_Nodes --chain-id=furya-1`;

const nodeInstallGenesis = `curl -Ls https://support.synergynodes.com/genesis/furya_mainnet_mainnet/genesis.json > $HOME/.furyad/config/genesis.json`;

const nodeInstallAddrbook = `curl -Ls https://support.synergynodes.com/addrbook/furya_mainnet_mainnet/addrbook.json > $HOME/.furyad/config/addrbook.json`;

const nodeInstallPeers = `# Add / Update Peers
PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.furyad/config/config.toml`;

const nodeInstallService = `# Create Service
sudo tee /etc/systemd/system/furyad.service > /dev/null <<EOF
[Unit]
Description=furyad Daemon
#After=network.target
StartLimitInterval=350
StartLimitBurst=10

[Service]
Type=simple
User=node
ExecStart=/home/node/go/bin/furyad start
Restart=on-abort
RestartSec=30

[Install]
WantedBy=multi-user.target

[Service]
LimitNOFILE=1048576
EOF`;

const nodeInstallSnapshot = `# Download the snapshot
` + snapOne + `

# Decompress the snapshot
` + snapTwo;

const nodeInstallStart = `# Enable service
sudo systemctl enable furyad

# Start service
sudo service furyad start

# Check logs
sudo journalctl -fu furyad`;


  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  const toggleBelow9 = () => {
    setBelowVisible9(!isBelowVisible9);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   

  const toggleBack = () => {
    setBelowVisible1(false);
    setBelowVisible8(false);
    setBelowVisible2(false);
    setBelowVisible3(false);
    setBelowVisible4(false);
    setBelowVisible5(false);
    setBelowVisible7(false);
    setBelowVisible9(false);
    setBack(!isBackVisible);
    setToolsMenu(true);
  };  

  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={furya}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Furya Mainnet
            <span className="myurl"><a href="https://fanfury.xyz" target="_blank">https://fanfury.xyz</a></span>
          </div>
        </div>
        
        
        
        <hr className="myhr"></hr>

        {toolsMenu && (
          <div className="toolBoxMain">        
            <div className="toolBox" onClick={toggleBelow1}>
              Node Snapshot
            </div>
            <div className="toolBox" onClick={toggleBelow8}>
              Node Installation
            </div>            
            <div className="toolBox" onClick={toggleBelow2}>
              Public Endpoints
            </div>           
            <div className="toolBox" onClick={toggleBelow3}>
              Persistent Peers
            </div>  
            <div className="toolBox" onClick={toggleBelow4}>
              AddrBook File
            </div>   
            <div className="toolBox" onClick={toggleBelow5}>
              Genesis File
            </div>  
           <div className="toolBox" onClick={toggleBelow7}>
              IBC Relayers
            </div>
           <div className="toolBox" onClick={toggleBelow9}>
              Auto Compounding
            </div>  
          </div>
        )}

        {!isBackVisible && (
          <div className="toolBoxMain">
            <div className="toolBoxBack" onClick={toggleBack}>
              Go Back
            </div>
          </div>
        )}             




          {isBelowVisible1 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Snapshot</div>
            </div>            
            <div className="below">
              <div className="snap">
                <b>Type:</b> Pruned Snapshot <br />
                <b>Version:</b> furyad {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapOne}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapTwo}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}





          
          {isBelowVisible8 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Installation</div>
            </div>            
            <div className="below">
              
              <br />
              <b>Login as User and install required tools</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallUser}
              </SyntaxHighlighter>              
              
              <br />
              <b>Install Go</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGo}
              </SyntaxHighlighter>

              <br />
              <b>Install the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallNode}
              </SyntaxHighlighter>

              <br />
              <b>Initialize the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallInitialize}
              </SyntaxHighlighter>              
              
              <br />
              <b>Download Genesis file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGenesis}
              </SyntaxHighlighter>    
              
              <br />
              <b>Download Addrbook file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallAddrbook}
              </SyntaxHighlighter>    
              
              <br />
              <b>Add / Update Persistent Peers</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallPeers}
              </SyntaxHighlighter> 
              
              <br />
              <b>Download & decompress Snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallSnapshot}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Create Service File</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallService}
              </SyntaxHighlighter>     
              
              <br />
              <b>Start the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallStart}
              </SyntaxHighlighter>                 

            </div>
            </div>
          )}
       




          
          {isBelowVisible2 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Public Endpoints</div>
            </div>
              <div className="below"><a href="https://furya-api.synergynodes.com">https://furya-api.synergynodes.com</a></div>
              <div className="belowtext">
              <a href="https://furya-rpc.synergynodes.com">https://furya-rpc.synergynodes.com</a>
              </div>
            </div>
          )}





          {isBelowVisible3 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Persistent Peers</div>
            </div>
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible4 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Addrbook File</div>
            </div>
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </div>
          )}
 
        


          {isBelowVisible5 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Genesis File</div>
            </div>
              <div className="below">
              We recommend you to download the genesis.json file from Furya official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </div>
          )}
   
        
        

          {isBelowVisible7 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">IBC Relayers</div>
            </div>
            <div className="below">
            Coming soon....
            </div>
          </div>
          )}
      


        {isBelowVisible9 && (
          <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Auto Compounding</div>
            </div>
            <div className="below">
            Coming soon....
            </div>
          </div>
        )}


      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceFurya;
