import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import image from "../../images/persistence.png";
import arrow from "../../images/arrow.jpg";
import sui from "../../images/item1.png";
import tenet from "../../images/item2.png";
import axelar from "../../images/item3.png";
import terraclassic from "../../images/item4.png";
import mars from "../../images/item5.png";
import kujira from "../../images/item6.png";
import stride from "../../images/item7.png";
import migaloo from "../../images/item8.png";
import planq from "../../images/item9.png";
import canto from "../../images/item10.png";
import acre from "../../images/item11.png";
import terra from "../../images/terra.png";
import oraichain from "../../images/item13.png";
import provenance from "../../images/item14.png";
import meme from "../../images/item15.png";
import injective from "../../images/injective.png";
import persistence from "../../images/persistence.png";
import sei from "../../images/sei.png";
import cosmoshub from "../../images/cosmos.png";
import evmos from "../../images/evmos.png";
import chihuahua from "../../images/chihuahua.png";
import osmosis from "../../images/osmosis.png";
import juno from "../../images/juno.png";
import gravity from "../../images/gravity.png";
import kava from "../../images/kava.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { snapshots } from '../../components/Chains/snapshots';
import { peersPersistencetest } from '../../components/Peers/peersPersistencetest';
import { Link } from "react-router-dom";

const ServicePersistencetest = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();
      setVersion(data[0]["persistence_testnet"].version);
      setSize(data[0]["persistence_testnet"].size)
      setBlock(data[0]["persistence_testnet"].block);
      setTimestamp(data[0]["persistence_testnet"].timestamp);
      setFile(data[0]["persistence_testnet"].file);
      setLink(data[0]["persistence_testnet"].link);
    }

    myFetch()
      .catch(console.error);  

  }, []);

  const [toolsMenu, setToolsMenu] = useState(true);
  const [isBackVisible, setBack] = useState(true);
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const [isBelowVisible9, setBelowVisible9] = useState(false);
  const [isBelowVisible10, setBelowVisible10] = useState(false);
  const [isBelowVisible11, setBelowVisible11] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersPersistencetest['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.persistenceCore/config/config.toml`;


  const snapOne = `wget -O ` + file + ` ` + link;


  const snapTwo = `lz4 -c -d ` + file + ` | tar -x -C $HOME/.persistenceCore`;

  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/persistence_testnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.persistenceCore/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis/persistence_testnet/genesis.json --inet4-only
mv genesis.json $HOME/.persistenceCore/config`;



const nodeInstallUser = `sudo apt update
sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y`;

const nodeInstallGo = `# Install Go
cd $HOME
curl https://dl.google.com/go/go1.20.5.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -
  
# Update environment variables to include go
cat <<'EOF' >>$HOME/.profile
export GOROOT=/usr/local/go
export GOPATH=$HOME/go
export GO111MODULE=on
export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
EOF
  
source $HOME/.profile
  
# check go version
go version`;


const nodeInstallNode = `# Install Persistence node
git clone https://github.com/persistenceOne/persistenceCore persistence
cd persistence
git checkout `+ version +`
make install
cd
persistenceCore version --long`;


const nodeInstallInitialize = `# Initialize Node
persistenceCore init <moniker> --chain-id=test-core-2

# Example:
persistenceCore init Synergy_Nodes --chain-id=test-core-2`;

const nodeInstallGenesis = `curl -Ls https://support.synergynodes.com/genesis/persistence_testnet/genesis.json > $HOME/.persistenceCore/config/genesis.json`;

const nodeInstallAddrbook = `curl -Ls https://support.synergynodes.com/addrbook/persistence_testnet/addrbook.json > $HOME/.persistenceCore/config/addrbook.json`;

const nodeInstallPeers = `# Add / Update Peers
PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.persistenceCore/config/config.toml`;

const nodeInstallService = `# Create Service
sudo tee /etc/systemd/system/persistence.service > /dev/null <<EOF
[Unit]
Description=persistence Daemon
#After=network.target
StartLimitInterval=350
StartLimitBurst=10

[Service]
Type=simple
User=node
ExecStart=/home/node/go/bin/persistenceCore start
Restart=on-abort
RestartSec=30

[Install]
WantedBy=multi-user.target

[Service]
LimitNOFILE=1048576
EOF`;

const nodeInstallSnapshot = `# Download the snapshot
` + snapOne + `

# Decompress the snapshot
` + snapTwo;

const nodeInstallStart = `# Enable service
sudo systemctl enable persistence

# Start service
sudo service persistence start

# Check logs
sudo journalctl -fu persistence`;


const statesyncURL = `https://persistence-testnet-rpc.synergynodes.com:443`;

const statesync1 = `#!/bin/bash

SNAP_RPC="https://persistence-testnet-rpc.synergynodes.com:443"
LATEST_HEIGHT=$(curl -s $SNAP_RPC/block | jq -r .result.block.header.height); \\
BLOCK_HEIGHT=$((LATEST_HEIGHT - 2000)); \\
TRUST_HASH=$(curl -s "$SNAP_RPC/block?height=$BLOCK_HEIGHT" | jq -r .result.block_id.hash)
echo $LATEST_HEIGHT $BLOCK_HEIGHT $TRUST_HASH

sed -i.bak -E "s|^(enable[[:space:]]+=[[:space:]]+).*$|\\1true| ; \\
s|^(rpc_servers[[:space:]]+=[[:space:]]+).*$|\\1\\"$SNAP_RPC,$SNAP_RPC\\"| ; \\
s|^(trust_height[[:space:]]+=[[:space:]]+).*$|\\1$BLOCK_HEIGHT| ; \\
s|^(trust_hash[[:space:]]+=[[:space:]]+).*$|\\1\\"$TRUST_HASH\\"|" $HOME/.persistenceCore/config/config.toml
more ~/.persistenceCore/config/config.toml | grep 'rpc_servers'
more ~/.persistenceCore/config/config.toml | grep 'trust_height'
more ~/.persistenceCore/config/config.toml | grep 'trust_hash'
`;

const statesyncTwo = `chmod 700 state_sync.sh
./state_sync.sh`;

const statesyncThree = `sudo service persistence stop`;

const statesyncFour = `persistence tendermint unsafe-reset-all --home $HOME/.persistenceCore --keep-addr-book`;

const statesyncFive = `sudo service persistence start`;

const seednodeOne = `0f2f4f381ebbd8443ff982b8db4c31dc1dceef1e@persistence-testnet-rpc.synergynodes.com:17656`;

const seednodeTwo = `seed="0f2f4f381ebbd8443ff982b8db4c31dc1dceef1e@persistence-testnet-rpc.synergynodes.com:17656"
sed -i.bak -e "s/^seed *=.*/seed = \\"$seed\\"/" ~/.persistenceCore/config/config.toml`;

const seednodeThree = `sudo systemctl restart persistence
journalctl -u persistence -f`;


  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  const toggleBelow9 = () => {
    setBelowVisible9(!isBelowVisible9);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow10 = () => {
    setBelowVisible10(!isBelowVisible10);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  const toggleBelow11 = () => {
    setBelowVisible11(!isBelowVisible11);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };     

  const toggleBack = () => {
    setBelowVisible1(false);
    setBelowVisible8(false);
    setBelowVisible2(false);
    setBelowVisible3(false);
    setBelowVisible4(false);
    setBelowVisible5(false);
    setBelowVisible7(false);
    setBelowVisible9(false);
    setBelowVisible10(false);
    setBelowVisible11(false);
    setBack(!isBackVisible);
    setToolsMenu(true);
  };


  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={image}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Persistence (Testnet)
            <span className="myurl"><a href="https://persistence.one" target="_blank">https://persistence.one</a></span>
          </div>
        </div>
        
        


        <div className="myStake">
          <a href="https://testnets.cosmosrun.info/persistence/staking/persistencevaloper1xzern65ppact6sgh4htllnr8pg9dpnc8yll5vy" target="_blank">Stake with Synergy Nodes</a>
        </div>
        
        <hr className="myhr"></hr>

        {toolsMenu && (
          <div className="toolBoxMain">         
          <div className="toolBox" onClick={toggleBelow9}>
          Explorer
        </div>          
            <div className="toolBox" onClick={toggleBelow1}>
              Node Snapshot
            </div>
            <div className="toolBox" onClick={toggleBelow10}>
              State Sync
            </div>            
            <div className="toolBox" onClick={toggleBelow8}>
              Node Installation
            </div>            
            <div className="toolBox" onClick={toggleBelow2}>
              Public Endpoints
            </div>         
            <div className="toolBox" onClick={toggleBelow3}>
              Persistent Peers
            </div>  
            <div className="toolBox" onClick={toggleBelow4}>
              AddrBook File
            </div>   
            <div className="toolBox" onClick={toggleBelow5}>
              Genesis File
            </div>  
            <div className="toolBox" onClick={toggleBelow11}>
              Seed Node
            </div>             
          </div>
        )}

        {!isBackVisible && (
          <div className="toolBoxMain">
            <div className="toolBoxBack" onClick={toggleBack}>
              Go Back
            </div>
          </div>
        )}                 


        {isBelowVisible9 && (
          <div className="node-box" style={{ height: "100%" }}>
          <div className="upper">
            <div className="right">Explorer</div>
          </div>
            <div className="below"><a href="https://testnets.cosmosrun.info/persistence">https://testnets.cosmosrun.info/persistence</a></div>         
          </div>
        )}


          {isBelowVisible1 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Snapshot</div>
            </div>            
            <div className="below">
              <div className="snap">
                <b>Type:</b> Pruned Snapshot <br />
                <b>Version:</b> persistenceCore {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapOne}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapTwo}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}




          {isBelowVisible10 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">State Sync</div>
            </div>
              <div className="below">
                Our state-sync RPC server
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncURL}
                </SyntaxHighlighter>
                Create a shell script file with the name <b>state_sync.sh</b> and add the following code. This will fetch the details about state sync block height and hash from our server and will update this information on your <b>config1.toml</b>.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesync1}
                </SyntaxHighlighter>
                Grant execute permission to the script and run the script.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncTwo}
                </SyntaxHighlighter>
                Stop the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncThree}
                </SyntaxHighlighter>   
                Reset the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncFour}
                </SyntaxHighlighter>  
                Start the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncFive}
                </SyntaxHighlighter>                                               
              </div>
            </div>
          )}






          {isBelowVisible8 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Installation</div>
            </div>            
            <div className="below">
              
              <br />
              <b>Login as User and install required tools</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallUser}
              </SyntaxHighlighter>              
              
              <br />
              <b>Install Go</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGo}
              </SyntaxHighlighter>

              <br />
              <b>Install the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallNode}
              </SyntaxHighlighter>

              <br />
              <b>Initialize the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallInitialize}
              </SyntaxHighlighter>              
              
              <br />
              <b>Download Genesis file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGenesis}
              </SyntaxHighlighter>    
              
              <br />
              <b>Download Addrbook file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallAddrbook}
              </SyntaxHighlighter>    
              
              <br />
              <b>Add / Update Persistent Peers</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallPeers}
              </SyntaxHighlighter> 
              
              <br />
              <b>Download & decompress Snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallSnapshot}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Create Service File</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallService}
              </SyntaxHighlighter>     
              
              <br />
              <b>Start the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallStart}
              </SyntaxHighlighter>                 

            </div>
            </div>
          )}
    





          {isBelowVisible2 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Public Endpoints</div>
            </div>
              <div className="below"><a href="https://persistence-testnet-api.synergynodes.com/">https://persistence-testnet-api.synergynodes.com/</a></div>
              <div className="belowtext">
              <a href="https://persistence-testnet-rpc.synergynodes.com/">https://persistence-testnet-rpc.synergynodes.com/</a>
              </div>
              <div className="belowtext">
              <a href="https://persistence-testnet-grpc.synergynodes.com/">https://persistence-testnet-grpc.synergynodes.com/</a>
              </div>              
            </div>
          )}




          {isBelowVisible3 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Persistent Peers</div>
            </div>
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible4 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Addrbook File</div>
            </div>
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </div>
          )}
 
        

 
          {isBelowVisible5 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Genesis File</div>
            </div>
              <div className="below">
              We recommend you to download the genesis.json file from persistence official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </div>
          )}
 


        

          {isBelowVisible7 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">IBC Relayers</div>
            </div>
              <div className="below">

              <div className="ibc">
                <span><img src={image} /> <br /> Persistence</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={kujira} /> <br /> Kujira</span>         
              </div>

              <div className="ibc">
                <span><img src={image} /> <br /> Persistence</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={axelar} /> <br /> Axelar</span>         
              </div>
              
              <div className="ibc">
                <span><img src={image} /> <br /> Persistence</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={injective} /> <br /> Injective</span>         
              </div>
              
              <div className="ibc">
                <span><img src={image} /> <br /> Persistence</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={canto} /> <br /> Canto</span>         
              </div>  
              
              <div className="ibc">
                <span><img src={image} /> <br /> Persistence</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={kava} /> <br /> Kava</span>         
              </div>               

              </div>
            </div>
          )}
   



        {isBelowVisible6 && (
          <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Auto Compounding</div>
            </div>
            <div className="below">
              We provide auto compounding for our delegators at no cost for Persistence.

              <div className="buttons-restake">
                <Link className="button1" to="https://restake.app">
                  <div className="button-text">Visit ReStake.app</div>
                </Link>
              </div>

            </div>
          </div>
        )}


        {isBelowVisible11 && (
          <div className="node-box" style={{ height: "100%" }}>
              <div className="upper">
              <div className="right">Seed Node</div>
              </div>
              <div className="below">
              <br />
              <center><b>{seednodeOne}</b></center>
              <br />
              Set Seed Node:
                <SyntaxHighlighter language="shell" style={darcula}>
                  {seednodeTwo}
                </SyntaxHighlighter>
                <br />
                Restart Node:
                  <SyntaxHighlighter language="shell" style={darcula}>
                    {seednodeThree}
                  </SyntaxHighlighter>                  
              </div>                
          </div>
        )}



      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServicePersistencetest;
