import "../styles/Header.scss";
import React from "react";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="Header">
      <Link className="logo-panel" to="/">
        <img src={Logo} className="logo" alt="logo"></img>
        <div className="head">Synergy Nodes</div>
      </Link>
      <div className={`links ${isOpen ? "open" : ""}`}>
        <Link className="link" to="/about">
          About
        </Link>
        <Link className="link" to="/security">
          Security
        </Link>
        <Link className="link" to="/nodes">
          Nodes
        </Link>
        <Link className="link" to="/contact">
          Contact us
        </Link>
      </div>
      <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      {isOpen && (
        <div className="popupMenu">
          <Link className="link" onClick={() => setIsOpen(false)} to="/about">
            About
          </Link>
          <Link
            className="link"
            onClick={() => setIsOpen(false)}
            to="/security"
          >
            Security
          </Link>
          <Link className="link" onClick={() => setIsOpen(false)} to="/node">
            Node
          </Link>
          <Link className="link" onClick={() => setIsOpen(false)} to="/contact">
            Contact us
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
