import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import storyimg from "../../images/story.jpg";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { snapshots } from '../../components/Chains/snapshots';
import { peersStorytestnet } from '../../components/Peers/peersStorytestnet';
import { Helmet } from 'react-helmet';

const ServiceStorytestnet = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');
  
  const [versionGeth, setVersionGeth] = useState('');
  const [sizeGeth, setSizeGeth] = useState('');
  const [blockGeth, setBlockGeth] = useState('');
  const [timestampGeth, setTimestampGeth] = useState('');
  const [fileGeth, setFileGeth] = useState('');
  const [linkGeth, setLinkGeth] = useState('');  

  const [versionA, setVersionA] = useState('');
  const [sizeA, setSizeA] = useState('');
  const [blockA, setBlockA] = useState('');
  const [timestampA, setTimestampA] = useState('');
  const [fileA, setFileA] = useState('');
  const [linkA, setLinkA] = useState('');
  
  const [versionGethA, setVersionGethA] = useState('');
  const [sizeGethA, setSizeGethA] = useState('');
  const [blockGethA, setBlockGethA] = useState('');
  const [timestampGethA, setTimestampGethA] = useState('');
  const [fileGethA, setFileGethA] = useState('');
  const [linkGethA, setLinkGethA] = useState('');  

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();

      setVersion(data[0]["story_testnet_story"].version);
      setSize(data[0]["story_testnet_story"].size)
      setBlock(data[0]["story_testnet_story"].block);
      setTimestamp(data[0]["story_testnet_story"].timestamp);
      setFile(data[0]["story_testnet_story"].file);
      setLink(data[0]["story_testnet_story"].link);

      setVersionGeth(data[0]["story_testnet_geth"].version);
      setSizeGeth(data[0]["story_testnet_geth"].size)
      setBlockGeth(data[0]["story_testnet_geth"].block);
      setTimestampGeth(data[0]["story_testnet_geth"].timestamp);
      setFileGeth(data[0]["story_testnet_geth"].file);
      setLinkGeth(data[0]["story_testnet_geth"].link);    
      
      setVersionA(data[0]["story_archive_story"].version);
      setSizeA(data[0]["story_archive_story"].size)
      setBlockA(data[0]["story_archive_story"].block);
      setTimestampA(data[0]["story_archive_story"].timestamp);
      setFileA(data[0]["story_archive_story"].file);
      setLinkA(data[0]["story_archive_story"].link);

      setVersionGethA(data[0]["story_archive_geth"].version);
      setSizeGethA(data[0]["story_archive_geth"].size)
      setBlockGethA(data[0]["story_archive_geth"].block);
      setTimestampGethA(data[0]["story_archive_geth"].timestamp);
      setFileGethA(data[0]["story_archive_geth"].file);
      setLinkGethA(data[0]["story_archive_geth"].link);         
    }

    myFetch()
      .catch(console.error);  

  }, []);
   
  const [toolsMenu, setToolsMenu] = useState(true);
  const [isBackVisible, setBack] = useState(true);
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const [isBelowVisible9, setBelowVisible9] = useState(false);
  const [isBelowVisible10, setBelowVisible10] = useState(false);
  const [isBelowVisible11, setBelowVisible11] = useState(false);
  const [isBelowVisible12, setBelowVisible12] = useState(false);
  const [isBelowVisible13, setBelowVisible13] = useState(false);
  const [isBelowVisible14, setBelowVisible14] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersStorytestnet['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.story/story/config/config.toml`;


  const snapOneStory = `wget -O ` + file + ` ` + link;
  const snapTwoStory = `lz4 -c -d ` + file + ` | tar -x -C $HOME/.story/story`;

  const snapOneGeth = `wget -O ` + fileGeth + ` ` + linkGeth;
  const snapTwoGeth = `lz4 -c -d ` + fileGeth + ` | tar -x -C $HOME/.story/geth/odyssey/geth`;  

  const snapAOneStory = `wget -O ` + fileA + ` ` + linkA;
  const snapATwoStory = `lz4 -c -d ` + fileA + ` | tar -x -C $HOME/.story/story`;

  const snapAOneGeth = `wget -O ` + fileGethA + ` ` + linkGethA;
  const snapATwoGeth = `lz4 -c -d ` + fileGethA + ` | tar -x -C $HOME/.story/geth/odyssey/geth`;  
  
  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/story_testnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.story/story/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis/story_testnet/genesis.json --inet4-only
mv genesis.json $HOME/.story/story/config`;



const nodeInstallUser = `sudo apt update
sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y`;

const nodeInstallGo = `# Install Go
cd $HOME
curl https://dl.google.com/go/go1.22.5.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -
  
# Update environment variables to include go
cat <<'EOF' >>$HOME/.profile
export GOROOT=/usr/local/go
export GOPATH=$HOME/go
export GO111MODULE=on
export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
EOF
  
source $HOME/.profile
  
# check go version
go version`;


const nodeInstallNode = `# Install Story Testnet node
cd $HOME
rm -rf story
git clone https://github.com/piplabs/story.git
cd story
git checkout ` + version + `
go build -o story ./client
cp story $HOME/go/bin
cd $HOME

cd $HOME
rm -rf story-geth
git clone https://github.com/piplabs/story-geth.git
cd story-geth
git checkout ` + versionGeth + `
make geth
cp $HOME/story-geth/build/bin/geth $HOME/go/bin
cd $HOME

mkdir -p $HOME/.story/story
mkdir -p $HOME/.story/geth`;

const nodeInstallInitialize = `# Initialize Node
story init --moniker <moniker> --network odyssey

# Example:
story init --moniker "Synergy Nodes" --network odyssey

# Initialize geth
geth --odyssey --syncmode full
# Press Ctrl+C to exit
`;

const nodeInstallGenesis = `curl -Ls https://support.synergynodes.com/genesis/story_testnet/genesis.json > $HOME/.story/story/config/genesis.json`;

const nodeInstallAddrbook = `curl -Ls https://support.synergynodes.com/addrbook/story_testnet/addrbook.json > $HOME/.story/story/config/addrbook.json`;

const nodeInstallPeers = `# Add / Update Peers
PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.story/story/config/config.toml`;

const nodeInstallServiceGeth = `# Create Geth Service
sudo tee /etc/systemd/system/geth.service > /dev/null <<EOF
[Unit]
Description=Geth daemon
After=network-online.target

[Service]
User=$USER
ExecStart=$HOME/go/bin/geth --odyssey --syncmode full
Restart=on-failure
RestartSec=3
LimitNOFILE=1048576

[Install]
WantedBy=multi-user.target
EOF`;

const nodeInstallServiceStory = `# Create Story Service
sudo tee /etc/systemd/system/story.service > /dev/null <<EOF
[Unit]
Description=Story Service
After=network.target

[Service]
User=$USER
Group=$USER
WorkingDirectory=$HOME/.story/story
ExecStart=$HOME/go/bin/story run

LimitNOFILE=65535
KillMode=process
KillSignal=SIGINT
TimeoutStopSec=90
Restart=on-failure
RestartSec=10s

[Install]
WantedBy=multi-user.target
EOF`;

const nodeInstallSnapshot = `# Download the snapshot
` + snapOneStory + `

# Decompress the snapshot
` + snapTwoStory;

const nodeInstallStart = `# Enable both geth and story services
sudo systemctl enable geth
sudo systemctl enable story

# Start the services
sudo service geth start
sudo service story start

# Check logs
sudo journalctl -fu geth
sudo journalctl -fu story`;



const statesyncURL = `https://story-testnet-rpc.synergynodes.com:443`;

const statesync1 = `#!/bin/bash

SNAP_RPC="https://story-testnet-rpc.synergynodes.com:443"
LATEST_HEIGHT=$(curl -s $SNAP_RPC/block | jq -r .result.block.header.height); \\
BLOCK_HEIGHT=$((LATEST_HEIGHT - 2000)); \\
TRUST_HASH=$(curl -s "$SNAP_RPC/block?height=$BLOCK_HEIGHT" | jq -r .result.block_id.hash)
echo $LATEST_HEIGHT $BLOCK_HEIGHT $TRUST_HASH

sed -i.bak -E "s|^(enable[[:space:]]+=[[:space:]]+).*$|\\1true| ; \\
s|^(rpc_servers[[:space:]]+=[[:space:]]+).*$|\\1\\"$SNAP_RPC,$SNAP_RPC\\"| ; \\
s|^(trust_height[[:space:]]+=[[:space:]]+).*$|\\1$BLOCK_HEIGHT| ; \\
s|^(trust_hash[[:space:]]+=[[:space:]]+).*$|\\1\\"$TRUST_HASH\\"|" $HOME/.story/story/config/config.toml
more ~/.story/story/config/config.toml | grep 'rpc_servers'
more ~/.story/story/config/config.toml | grep 'trust_height'
more ~/.story/story/config/config.toml | grep 'trust_hash'
`;

const statesyncTwo = `chmod 700 state_sync.sh
./state_sync.sh`;

const statesyncThree = `sudo service story stop`;

const statesyncFour = `story comet unsafe-reset-all --home $HOME/.story/story --keep-addr-book`;

const statesyncFive = `sudo service story start`;


const seednodeOne = `92782932b46812fb9772ccd7a1fedad924938246@story-testnet-seed.synergynodes.com:26656`;

const seednodeTwo = `seed="92782932b46812fb9772ccd7a1fedad924938246@story-testnet-seed.synergynodes.com:26656"
sed -i.bak -e "s/^seed *=.*/seed = \\"$seed\\"/" ~/.story/story/config/config.toml`;

const seednodeThree = `sudo systemctl restart story
journalctl -u story -f`;



  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow9 = () => {
    setBelowVisible9(!isBelowVisible9);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow10 = () => {
    setBelowVisible10(!isBelowVisible10);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow11 = () => {
    setBelowVisible11(!isBelowVisible11);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow12 = () => {
    setBelowVisible12(!isBelowVisible12);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };      
  const toggleBelow13 = () => {
    setBelowVisible13(!isBelowVisible13);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  const toggleBelow14 = () => {
    setBelowVisible14(!isBelowVisible14);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  
  const toggleBack = () => {
    setBelowVisible1(false);
    setBelowVisible8(false);
    setBelowVisible2(false);
    setBelowVisible3(false);
    setBelowVisible4(false);
    setBelowVisible5(false);
    setBelowVisible7(false);
    setBelowVisible9(false);
    setBelowVisible10(false);
    setBelowVisible11(false);
    setBelowVisible12(false);
    setBelowVisible13(false);
    setBelowVisible14(false);
    setBack(!isBackVisible);
    setToolsMenu(true);
  };

  return (
    <>
    <Header/>
    <Helmet>
      <title>Story Testnet (odyssey) - Services from Synergy Nodes</title>
      <meta name="description" content="Story Testnet (odyssey) - Services from Synergy Nodes" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Story Testnet (odyssey) - Services from Synergy Nodes" />
      <meta property="og:description" content="Story Testnet (odyssey) - Services from Synergy Nodes" />
      <meta property="og:url" content="https://www.synergynodes.com/service/storytest" />
      <meta property="og:image" content="https://synergynodes.com/synergy.png" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="180" />
      <meta property="og:image:height" content="180" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Story Testnet(odyssey) - Services from Synergy Nodes" />
      <meta name="twitter:description" content="Story Testnet(odyssey) - Services from Synergy Nodes" />
      <meta name="twitter:image" content="https://synergynodes.com/synergy.png" />      
    </Helmet>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={storyimg}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Story Testnet (odyssey)
            <span className="myurl"><a href="https://www.story.foundation" target="_blank">https://www.story.foundation</a></span>
          </div>
        </div>
        
        


      <div className="myStake">
        <a href="" target="_blank">Stake with Synergy Nodes</a>
      </div>
      
      <hr className="myhr"></hr>
  
      {toolsMenu && (

        <div>


        <div className="toolBoxMain">
          <p>Archive Node Snapshot</p>
          <div className="toolBox" onClick={toggleBelow11}>
            Story Archive Snapshot
          </div> 
          <div className="toolBox" onClick={toggleBelow12}>
            Geth Archive Snapshot
          </div> 
        </div>

        <hr className="myhr"></hr>

        <div className="toolBoxMain">
          <p>Pruned Node Snapshot</p>
          <div className="toolBox" onClick={toggleBelow1}>
            Story Snapshot
          </div>    
          <div className="toolBox" onClick={toggleBelow10}>
            Geth Snapshot
          </div> 
        </div>

        <hr className="myhr"></hr>        

        <div className="toolBoxMain">              
          <div className="toolBox" onClick={toggleBelow9}>
            Bech32 Tool
          </div>                
          <div className="toolBox" onClick={toggleBelow8}>
            Node Installation
          </div>     
          <div className="toolBox" onClick={toggleBelow13}>
            State Sync
          </div>                  
          <div className="toolBox" onClick={toggleBelow2}>
            Public Endpoints
          </div>          
          <div className="toolBox" onClick={toggleBelow3}>
            Persistent Peers
          </div>  
          <div className="toolBox" onClick={toggleBelow4}>
            AddrBook File
          </div>   
          <div className="toolBox" onClick={toggleBelow5}>
            Genesis File
          </div>
          <div className="toolBox" onClick={toggleBelow14}>
            Seed Node
          </div>                   
        </div>

        </div>
      )}
  
      {!isBackVisible && (
        <div className="toolBoxMain">
          <div className="toolBoxBack" onClick={toggleBack}>
            Go Back
          </div>
        </div>
      )}             
  

          {isBelowVisible9 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Bech32 Tool</div>
            </div> 
              <div className="below"><a href="https://tools.synergynodes.com">https://tools.synergynodes.com</a></div>         
            </div>
          )}




          {isBelowVisible11 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Story Node Snapshot (Archive)</div>
            </div>             
            <div className="below">
              <div className="snap">
                <b>Type:</b> Snapshot <br />
                <b>Version:</b> story {versionA} <br />
                <b>Block:</b> {blockA} <br />
                <b>Size:</b> {sizeA} <br />
                <b>Timestamp:</b> {timestampA} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapAOneStory}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapATwoStory}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}



          {isBelowVisible12 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Geth Snapshot (Archive)</div>
            </div>             
            <div className="below">
              <div className="snap">
                <b>Type:</b> Snapshot <br />
                <b>Version:</b> geth {versionGethA} <br />
                <b>Block:</b> {blockGethA} <br />
                <b>Size:</b> {sizeGethA} <br />
                <b>Timestamp:</b> {timestampGethA} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapAOneGeth}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapATwoGeth}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}





          {isBelowVisible1 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Story Node Snapshot (Pruned)</div>
            </div>             
            <div className="below">
              <div className="snap">
                <b>Type:</b> Snapshot <br />
                <b>Version:</b> story {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapOneStory}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapTwoStory}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}



          {isBelowVisible10 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Geth Snapshot (Pruned)</div>
            </div>             
            <div className="below">
              <div className="snap">
                <b>Type:</b> Snapshot <br />
                <b>Version:</b> geth {versionGeth} <br />
                <b>Block:</b> {blockGeth} <br />
                <b>Size:</b> {sizeGeth} <br />
                <b>Timestamp:</b> {timestampGeth} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapOneGeth}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapTwoGeth}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}




          {isBelowVisible8 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Installation</div>
            </div>             
            <div className="below">
              
              <br />
              <b>Login as User and install required tools</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallUser}
              </SyntaxHighlighter>              
              
              <br />
              <b>Install Go</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGo}
              </SyntaxHighlighter>

              <br />
              <b>Install the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallNode}
              </SyntaxHighlighter>

              <br />
              <b>Initialize the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallInitialize}
              </SyntaxHighlighter>              
              
              <br />
              <b>Download Genesis file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGenesis}
              </SyntaxHighlighter>    
              
              <br />
              <b>Download Addrbook file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallAddrbook}
              </SyntaxHighlighter>    
              
              <br />
              <b>Add / Update Persistent Peers</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallPeers}
              </SyntaxHighlighter> 
              
              <br />
              <b>Download & decompress Snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallSnapshot}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Create Geth Service File</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallServiceGeth}
              </SyntaxHighlighter>     

              <br />
              <b>Create Story Service File</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallServiceStory}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Start the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallStart}
              </SyntaxHighlighter>                 

            </div>
            </div>
          )}
      



          {isBelowVisible2 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Public Endpoints</div>
            </div> 
              <div className="below"><a href="https://story-testnet-api.synergynodes.com/">https://story-testnet-api.synergynodes.com/</a></div>
              <div className="belowtext">
              <a href="https://story-testnet-rpc.synergynodes.com/">https://story-testnet-rpc.synergynodes.com/</a>
              </div>
              <div className="belowtext">
              <a href="https://story-testnet-evm.synergynodes.com/">https://story-testnet-evm.synergynodes.com/</a>
              </div>              
            </div>
          )}





          {isBelowVisible3 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Persistent Peers</div>
            </div> 
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible4 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Addrbook File</div>
            </div> 
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </div>
          )}  
        

   
          {isBelowVisible5 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Genesis File</div>
            </div> 
              <div className="below">
              We recommend you to download the genesis.json file from Story official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible7 && (
            <div className="node-box" style={{ height: "100%" }}>
                <div className="upper">
                <div className="right">Seed Node</div>
                </div>
                <div className="below">
                <br />
                <center><b>{seednodeOne}</b></center>
                <br />
                Set Seed Node:
                  <SyntaxHighlighter language="shell" style={darcula}>
                    {seednodeTwo}
                  </SyntaxHighlighter>
                  <br />
                  Restart Node:
                    <SyntaxHighlighter language="shell" style={darcula}>
                      {seednodeThree}
                    </SyntaxHighlighter>                  
                </div>                
            </div>
          )}    
          


          {isBelowVisible13 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">State Sync</div>
            </div>
              <div className="below">
                Our state-sync RPC server
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncURL}
                </SyntaxHighlighter>
                Create a shell script file with the name <b>state_sync.sh</b> and add the following code. This will fetch the details about state sync block height and hash from our server and will update this information on your <b>config1.toml</b>.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesync1}
                </SyntaxHighlighter>
                Grant execute permission to the script and run the script.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncTwo}
                </SyntaxHighlighter>
                Stop the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncThree}
                </SyntaxHighlighter>   
                Reset the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncFour}
                </SyntaxHighlighter>  
                Start the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {statesyncFive}
                </SyntaxHighlighter>                                               
              </div>
            </div>
          )}

          
          
          {isBelowVisible14 && (
            <div className="node-box" style={{ height: "100%" }}>
                <div className="upper">
                <div className="right">Seed Node</div>
                </div>
                <div className="below">
                <br />
                <center><b>{seednodeOne}</b></center>
                <br />
                Set Seed Node:
                  <SyntaxHighlighter language="shell" style={darcula}>
                    {seednodeTwo}
                  </SyntaxHighlighter>
                  <br />
                  Restart Node:
                    <SyntaxHighlighter language="shell" style={darcula}>
                      {seednodeThree}
                    </SyntaxHighlighter>                  
                </div>                
            </div>
          )}          



      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceStorytestnet;
