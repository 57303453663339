import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import image from "../../images/item8.png";
import arrow from "../../images/arrow.jpg";
import sui from "../../images/item1.png";
import tenet from "../../images/item2.png";
import axelar from "../../images/item3.png";
import terraclassic from "../../images/item4.png";
import mars from "../../images/item5.png";
import kujira from "../../images/item6.png";
import stride from "../../images/item7.png";
import migaloo from "../../images/item8.png";
import planq from "../../images/item9.png";
import canto from "../../images/item10.png";
import acre from "../../images/item11.png";
import terra from "../../images/terra.png";
import oraichain from "../../images/item13.png";
import provenance from "../../images/item14.png";
import meme from "../../images/item15.png";
import injective from "../../images/injective.png";
import persistence from "../../images/persistence.png";
import sei from "../../images/sei.png";
import cosmoshub from "../../images/cosmos.png";
import evmos from "../../images/evmos.png";
import chihuahua from "../../images/chihuahua.png";
import osmosis from "../../images/osmosis.png";
import juno from "../../images/juno.png";
import gravity from "../../images/gravity.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { snapshots } from '../../components/Chains/snapshots';
import { peersWhitewhale } from '../../components/Peers/peersWhitewhale';
import { Link } from "react-router-dom";

const ServiceWhitewhale = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();
      setVersion(data[0]["whitewhale"].version);
      setSize(data[0]["whitewhale"].size)
      setBlock(data[0]["whitewhale"].block);
      setTimestamp(data[0]["whitewhale"].timestamp);
      setFile(data[0]["whitewhale"].file);
      setLink(data[0]["whitewhale"].link);
    }

    myFetch()
      .catch(console.error);  

  }, []);
    
  const [toolsMenu, setToolsMenu] = useState(true);
  const [isBackVisible, setBack] = useState(true);
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible9, setBelowVisible9] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersWhitewhale['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.migalood/config/config.toml`;


  const snapOne = `wget -O ` + file + ` ` + link;


  const snapTwo = `lz4 -c -d ` + file + ` | tar -x -C $HOME/.migalood`;

  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/whitewhale_mainnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.migalood/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis/whitewhale_mainnet/genesis.json --inet4-only
mv genesis.json $HOME/.migalood/config`;

  const nodeInstall = `# Update System and install required tools
  sudo apt update
  sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y

  # Install Go
  cd $HOME
  curl https://dl.google.com/go/go1.20.5.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -

  # Update environment variables to include go
  cat <<'EOF' >>$HOME/.profile
  export GOROOT=/usr/local/go
  export GOPATH=$HOME/go
  export GO111MODULE=on
  export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
  EOF

  source $HOME/.profile
`;

  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow9 = () => {
    setBelowVisible9(!isBelowVisible9);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   


  const toggleBack = () => {
    setBelowVisible1(false);
    setBelowVisible2(false);
    setBelowVisible3(false);
    setBelowVisible4(false);
    setBelowVisible5(false);
    setBelowVisible7(false);
    setBelowVisible9(false);
    setBack(!isBackVisible);
    setToolsMenu(true);
  };

  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={image}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Whitewhale Mainnet
            <span className="myurl"><a href="https://whitewhale.money" target="_blank">https://whitewhale.money</a></span>
          </div>
        </div>
        
        

        <div className="myStake">
        <a href="https://explorer.silknodes.io/whitewhale/staking/migaloovaloper1xesqr8vjvy34jhu027zd70ypl0nnev5eec90ew" target="_blank">Stake with Synergy Nodes</a>
      </div>
      
      <hr className="myhr"></hr>
  
      {toolsMenu && (
        <div className="toolBoxMain">       
          <div className="toolBox" onClick={toggleBelow1}>
            Node Snapshot
          </div>          
          <div className="toolBox" onClick={toggleBelow2}>
            Public Endpoints
          </div>          
          <div className="toolBox" onClick={toggleBelow3}>
            Persistent Peers
          </div>  
          <div className="toolBox" onClick={toggleBelow4}>
            AddrBook File
          </div>   
          <div className="toolBox" onClick={toggleBelow5}>
            Genesis File
          </div>  
         <div className="toolBox" onClick={toggleBelow7}>
            IBC Relayers
          </div>
         <div className="toolBox" onClick={toggleBelow9}>
            Auto Compounding
          </div>  
        </div>
      )}
  
      {!isBackVisible && (
        <div className="toolBoxMain">
          <div className="toolBoxBack" onClick={toggleBack}>
            Go Back
          </div>
        </div>
      )}             
  


          {isBelowVisible1 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Snapshot</div>
            </div>             
            <div className="below">
              <div className="snap">
                <b>Type:</b> Pruned Snapshot <br />
                <b>Version:</b> migalood {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapOne}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapTwo}
              </SyntaxHighlighter>              

            </div>
            </div>
          )}




 
          {isBelowVisible2 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Public Endpoints</div>
            </div> 
              <div className="below"><a href="https://whitewhale-api.synergynodes.com/">https://whitewhale-api.synergynodes.com/</a></div>
              <div className="belowtext">
              <a href="https://whitewhale-rpc.synergynodes.com/">https://whitewhale-rpc.synergynodes.com/</a>
              </div>
            </div>
          )}





          {isBelowVisible3 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Persistent Peers</div>
            </div> 
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible4 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Addrbook File</div>
            </div> 
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </div>
          )}
   
        


          {isBelowVisible5 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Genesis File</div>
            </div> 
              <div className="below">
              We recommend you to download the genesis.json file from Whitewhale (Migaloo) official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </div>
          )}
 
        
        

          {isBelowVisible7 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">IBC Relayers</div>
            </div> 
              <div className="below">

              <div className="ibc">
                <span><img src={image} /> <br /> Migaloo</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={terra} /> <br /> Terra</span>         
              </div>
              
              <div className="ibc">
                <span><img src={image} /> <br /> Migaloo</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={juno} /> <br /> Juno</span>         
              </div>

              <div className="ibc">
                <span><img src={image} /> <br /> Migaloo</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={axelar} /> <br /> Axelar</span>         
              </div>
              
              <div className="ibc">
                <span><img src={image} /> <br /> Migaloo</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={kujira} /> <br /> Kujira</span>         
              </div>
              
              <div className="ibc">
                <span><img src={image} /> <br /> Migaloo</span>
                <span><img src={arrow} /> <br /><br /></span>
                <span><img src={injective} /> <br /> Injective</span>         
              </div>              
              
              </div>
            </div>
          )}
       



 
        {isBelowVisible9 && (
          <div className="node-box" style={{ height: "100%" }}>
          <div className="upper">
            <div className="right">Auto Compounding</div>
          </div> 
            <div className="below">
              We provide auto compounding for our delegators at no cost for Whitewhale (Migaloo).

              <div className="buttons-restake">
                <Link className="button1" to="https://restake.app">
                  <div className="button-text">Visit ReStake.app</div>
                </Link>
              </div>

            </div>
          </div>
        )}        



      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceWhitewhale;
