import "../../styles/Contact/ContactForm.scss";
import image from "../../images/contact.png";

const ContactForm = () => {
  return (
    <div className="contactform-back">
      <div className="contactform">
        <div className="subtitle">Get in touch</div>
        <div className="text">
          To get in touch with us, please send email to <br />
          <a href="mailto:contact@synergynodes.com" style={{color: "#fff", fontSize: "25px", paddingTop: "10px", lineHeight: "50px"}}>contact@synergynodes.com</a>
        </div>

      </div>
    </div>
  );
};

export default ContactForm;
