import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import image from "../../images/sei.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { snapshots } from '../../components/Chains/snapshots';
import { peersTerra } from '../../components/Peers/peersTerra';

const ServiceSei = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const codeRef = useRef(null);

  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
  };   
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
  }; 

  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={image}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Sei Network
          </div>
        </div>
        
        


        <div
          className="node-box"
          style={{ height: isBelowVisible2 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">01</div>
            <div className="right">Public Endpoints</div>
            <div className="icon" onClick={toggleBelow2}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible2 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible2 && (
            <>
              <div className="below"><a href="https://sei-api.synergynodes.com">https://sei-api.synergynodes.com</a></div>
              <div className="belowtext">
              <a href="https://sei-rpc.synergynodes.com">https://sei-rpc.synergynodes.com</a>
              </div>
            </>
          )}
        </div>





      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceSei;
