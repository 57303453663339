import "../../styles/About/Team.scss";
import image1 from "../../images/team1.png";
import image2 from "../../images/team2.png";
import image3 from "../../images/team3.png";
import image4 from "../../images/team4.png";
import social1 from "../../images/social1.png";
import social2 from "../../images/social2.png";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <div className="team-back">
      <div className="team">
        <div className="subtitle">Our Team</div>
        <div className="title">Meet the Team</div>
        <div className="text">
          Allow us to introduce you to the talented individuals who are the
          heart and soul of our organization.
        </div>
        <div className="widget">
          <div className="item">
            <div className="icon">
              <img src={image1}></img>
            </div>
            <div className="title">Aries</div>
            <div className="subtitle">Founder & CEO</div>

            <div className="social">
              <a href="https://www.discordapp.com/users/406845516266209280" target="_blank"><img
                  className="social-icon"
                  style={{ marginRight: "8px" }}
                  src={social1}
                ></img></a>
              <a href="https://t.me/aries2501" target="_blank">
                <img className="social-icon" src={social2}></img>
              </a>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={image2}></img>
            </div>
            <div className="title">Capri</div>
            <div className="subtitle">Tech Support</div>

            <div className="social">
              <a href="https://www.discordapp.com/users/1045191398879678595" target="_blank">
                <img
                  className="social-icon"
                  style={{ marginRight: "8px" }}
                  src={social1}
                ></img>
              </a>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={image3}></img>
            </div>
            <div className="title">Scorp</div>
            <div className="subtitle">Tech Support</div>

            <div className="social">
              <a href="https://www.discordapp.com/users/968025543633166346" target="_blank">
                <img
                  className="social-icon"
                  style={{ marginRight: "8px" }}
                  src={social1}
                ></img>
              </a>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={image4}></img>
            </div>
            <div className="title">Callisto</div>
            <div className="subtitle">Business Development Manager</div>
            <div className="social">
              <a href="https://www.discordapp.com/users/549847797604679681" target="_blank">
                <img
                  className="social-icon"
                  style={{ marginRight: "8px" }}
                  src={social1}
                ></img> 
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
