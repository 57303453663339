import "../../styles/Home/Stats.scss";
import image1 from "../../images/item1.png";
import image3 from "../../images/item3.png";
import image4 from "../../images/item4.png";
import image7 from "../../images/item7.png";
import zetaimg from "../../images/test5.png";
import image6 from "../../images/item6.png";
import { Link } from "react-router-dom";


const Stats = () => {
  return (
    <div className="stats-back">
      <div className="stats">
        <div className="title">Featured Networks</div>
        <div className="list">
          <div className="content">
            <img src={image1}></img>
            <div className="title"><Link className="whiteLink" to="/nodes">Sui Network</Link></div>
          </div>
          <div className="content">
            <img src={image3}></img>
            <div className="title"><Link className="whiteLink" to="/nodes">Axelar</Link></div>
          </div>          
          <div className="content">
            <img src={zetaimg}></img>
            <div className="title"><Link className="whiteLink" to="/nodes">Zetachain</Link></div>
          </div>
          <div className="content">
            <img src={image4}></img>
            <div className="title"><Link className="whiteLink" to="/nodes">Terra</Link></div>
          </div>
          <div className="content">
            <img src={image7}></img>
            <div className="title"><Link className="whiteLink" to="/nodes">Stride</Link></div>
          </div>
          <div className="content">
            <img src={image6}></img>
            <div className="title"><Link className="whiteLink" to="/nodes">Kujira</Link></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
