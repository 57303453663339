import "../../styles/About/AboutIntro.scss";

const ContactIntro = () => {
  return (
    <div className="aboutintro-back">
      <div className="aboutintro">
        <div className="left">
          <div className="title">
            Contact Us
            <br />
            <span className="subtitle">Synergy Nodes</span>
          </div>
          <div className="text">
            We are a bunch of full time crypto & blockchain technology
            enthusiast who are committed to helping build core infrastructure
            for various blockchain protocols.
          </div>
        </div>
        <div className="right">
          We understand blockchain's widespread future impact on the world and
          are actively participating in its development and adoption. Bringing
          over 10 years of full time experience in crypto currency world dealing
          with countless POW and POS coins and tokens. Extensively have and do
          participate in countless ICOs such as EOS, ICON, METAHASH. Also have
          years of experience in running various Master Nodes, dedicated and
          cloud servers for various POS coins.
          <br />
          <br />
          We are committed to working with the crypto community on a mutual WIN
          WIN partnership through various crypto opportunities, primarily
          focusing on operating high quality and performance servers/nodes.
        </div>
      </div>
    </div>
  );
};

export default ContactIntro;
