import "../../styles/Security/SecurityIntro.scss";

const SecurityIntro = () => {
  return (
    <div className="securityintro-back">
      <div className="securityintro">
        <div className="left">
          <div className="title">
            Security
            <br />
            <span className="subtitle">Synergy Nodes</span>
          </div>
          <div className="text">
            At Synergy Nodes, we are committed to providing unmatched security
            and trust for blockchain networks as a reputable validator.
          </div>
        </div>
        <div className="right">
          With a team of seasoned blockchain experts, we operate with precision
          and dedication to ensure the seamless validation of transactions. Our
          state-of-the-art infrastructure and robust network connectivity
          guarantee exceptional uptime, minimizing the risk of disruptions and
          enhancing the overall reliability of the networks we validate.
          <br />
          <br />
          Security is at the core of everything we do. We implement cutting-edge
          encryption protocols, multi-layered authentication mechanisms, and
          regular security audits to safeguard the assets entrusted to us. You
          can trust Synergy Node to maintain the highest standards of data
          protection and privacy for your staked assets.
        </div>
      </div>
    </div>
  );
};

export default SecurityIntro;
