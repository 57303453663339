import Header from "./Header";
import NodeIntro from "./Node/NodeIntro";
import Mainnet from "./Home/Mainnet";
import Testnet from "./Home/Testnet";
import Subscribe from "./Home/Subscribe";
import Footer from "./Footer";

const Node = ({ handleOpenModal }) => {
  return (
    <>
      <Header />
      <Mainnet handleOpenModal={handleOpenModal} />
      <Testnet handleOpenModal={handleOpenModal} />
      <Footer />
    </>
  );
};

export default Node;
