import "../../styles/Home/Intro.scss";
import React from "react";
import image from "../../images/intro.png";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <div className="back">
      <div className="intro">
        <div className="text">
          <div className="title">
            Welcome to
            <span>
              <br />
            </span>
            <span className="subtitle">Synergy Nodes</span>
          </div>
          <ul>
            <li>$86M+ Total Assets Staked</li>
            <li>28 Networks, 24/7 Monitoring</li>
            <li>Over 14,000 Delegations</li>
            <li>Public Endpoints, Snapshots, Explorer</li>
            <li>Auto-compound, Peers, Addrbook</li>
            <li>IBC Relayers between 20+ chains</li>
          </ul>
          <Link className="button" to="/nodes">
            <div className="text">Stake Now</div>
          </Link>
        </div>
        <div className="image">
          <img src={image}></img>
        </div>
      </div>
    </div>
  );
};

export default Intro;
