import "../../styles/Security/Measure.scss";

import image1 from "../../images/sec1.png";
import image2 from "../../images/sec2.png";
import image3 from "../../images/sec3.png";
import image4 from "../../images/sec4.png";
import image5 from "../../images/sec5.png";
import image6 from "../../images/sec6.png";
import image7 from "../../images/sec7.png";
import image8 from "../../images/sec8.png";

const Measure = () => {
  return (
    <div className="measure-back">
      <div className="measure">
        <div className="subtitle">Security</div>
        <div className="title">Security Measures</div>
        <div className="text">
          We have setup the following security measures for all our Validator
          Nodes.
        </div>
        <div className="contents">
          <div className="item">
            <div className="icon-panel">
              <img src={image1} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">Firewall</div>
              <div className="content-text">
                Deny all incoming traffic except for few important ports, such
                as 26656.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image2} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">Root Access</div>
              <div className="content-text">
                Removed SSH login using root password.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image3} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">SSH Key</div>
              <div className="content-text">
                Hardware tied SSH Key Authentication to login to server. Without
                these keys, no one can login.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image4} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">IP Lock</div>
              <div className="content-text">
                SSH login only allowed from 1 main IP, and allow temporary
                access to an other IP during travel.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image5} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">SSH Port</div>
              <div className="content-text">
                Remove default SSH port 22 and we are using custom port for SSH.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image6} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">Servers Config</div>
              <div className="content-text">
                Dedicated powerful servers for all our nodes with NVMe SSD.
                Updated a few settings for best performance to the server.
                Regularly check for new updates for the OS and update them.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image7} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">Backup System</div>
              <div className="content-text">
                Fully synced Backup server and we can switch to backup server
                within 5 mins if main sever is down.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image8} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="content-title">Alert System</div>
              <div className="content-text">
                PagerDuty alerts using Tenderduty with phone calls and email
                alerts which we have setup in an other independent server. This
                will notify us through phone call and email anytime if we miss 3
                pre-commits.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Measure;
