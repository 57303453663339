import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import image from "../../images/terra.png";
import arrow from "../../images/arrow.jpg";
import sui from "../../images/item1.png";
import tenet from "../../images/item2.png";
import axelar from "../../images/item3.png";
import terraclassic from "../../images/item4.png";
import mars from "../../images/item5.png";
import kujira from "../../images/item6.png";
import stride from "../../images/item7.png";
import migaloo from "../../images/item8.png";
import planq from "../../images/item9.png";
import canto from "../../images/item10.png";
import acre from "../../images/item11.png";
import terra from "../../images/terra.png";
import oraichain from "../../images/item13.png";
import provenance from "../../images/item14.png";
import meme from "../../images/item15.png";
import injective from "../../images/injective.png";
import persistence from "../../images/persistence.png";
import sei from "../../images/sei.png";
import cosmoshub from "../../images/cosmos.png";
import evmos from "../../images/evmos.png";
import chihuahua from "../../images/chihuahua.png";
import osmosis from "../../images/osmosis.png";
import juno from "../../images/juno.png";
import gravity from "../../images/gravity.png";
import celestia from "../../images/celestia.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { snapshots } from '../../components/Chains/snapshots';
import { peersCelestiatest } from '../../components/Peers/peersCelestiatest';
import { Link } from "react-router-dom";

const ServiceCelestiatest = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();
      setVersion(data[0]["celestia_testnet"].version);
      setSize(data[0]["celestia_testnet"].size)
      setBlock(data[0]["celestia_testnet"].block);
      setTimestamp(data[0]["celestia_testnet"].timestamp);
      setFile(data[0]["celestia_testnet"].file);
      setLink(data[0]["celestia_testnet"].link);
    }

    myFetch()
      .catch(console.error);  

  }, []);
    
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const [isBelowVisible9, setBelowVisible9] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersCelestiatest['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.celestia-app/config/config.toml`;


  const snapOne = `wget -O ` + file + ` ` + link;


  const snapTwo = `lz4 -c -d ` + file + ` | tar -x -C $HOME/.celestia-app`;

  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/celestia_testnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.celestia-app/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis/celestia_testnet/genesis.json --inet4-only
mv genesis.json $HOME/.celestia-app/config`;

 

const nodeInstallUser = `sudo apt update
sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y`;

const nodeInstallGo = `# Install Go
cd $HOME
curl https://dl.google.com/go/go1.21.1.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -
  
# Update environment variables to include go
cat <<'EOF' >>$HOME/.profile
export GOROOT=/usr/local/go
export GOPATH=$HOME/go
export GO111MODULE=on
export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
EOF
  
source $HOME/.profile
  
# check go version
go version`;


const nodeInstallNode = `# Install Celestia node
git clone https://github.com/celestiaorg/celestia-app celestia
cd celestia
git checkout `+ version +`
make install
cd
celestia-appd version --long`;


const nodeInstallInitialize = `# Initialize Node
celestia-appd init <moniker> --chain-id=mocha-4

# Example:
celestia-appd init Synergy_Nodes --chain-id=mocha-4`;

const nodeInstallGenesis = `curl -Ls https://support.synergynodes.com/genesis/celestia_testnet/genesis.json > $HOME/.celestia-app/config/genesis.json`;

const nodeInstallAddrbook = `curl -Ls https://support.synergynodes.com/addrbook/celestia_testnet/addrbook.json > $HOME/.celestia-app/config/addrbook.json`;

const nodeInstallPeers = `# Add / Update Peers
PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.celestia-app/config/config.toml`;

const nodeInstallService = `# Create Service
sudo tee /etc/systemd/system/celestia.service > /dev/null <<EOF
[Unit]
Description=Celestia Daemon
#After=network.target
StartLimitInterval=350
StartLimitBurst=10

[Service]
Type=simple
User=node
ExecStart=/home/node/go/bin/celestia-appd start
Restart=on-abort
RestartSec=30

[Install]
WantedBy=multi-user.target

[Service]
LimitNOFILE=1048576
EOF`;

const nodeInstallSnapshot = `# Download the snapshot
` + snapOne + `

# Decompress the snapshot
` + snapTwo;

const nodeInstallStart = `# Enable service
sudo systemctl enable celestia

# Start service
sudo service celestia start

# Check logs
sudo journalctl -fu celestia`;


  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
  };   
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
  }; 
  const toggleBelow9 = () => {
    setBelowVisible9(!isBelowVisible9);
  };   

  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={celestia}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Celestia Testnet
          </div>
        </div>
        
        


        <div
          className="node-box"
          style={{ height: isBelowVisible1 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">01</div>
            <div className="right">Node Snapshot</div>
            <div className="icon" onClick={toggleBelow1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible1 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible1 && (
            <div className="below">
              <div className="snap">
                <b>Type:</b> Pruned Snapshot <br />
                <b>Version:</b> celestia-appd {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Download the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapOne}
              </SyntaxHighlighter>
              <br></br>

              <b>Decompress the snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {snapTwo}
              </SyntaxHighlighter>              

            </div>
          )}
        </div>




        <div
          className="node-box"
          style={{ height: isBelowVisible8 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">02</div>
            <div className="right">Node Installation</div>
            <div className="icon" onClick={toggleBelow8}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible8 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible8 && (
            <div className="below">
              
              <br />
              <b>Login as User and install required tools</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallUser}
              </SyntaxHighlighter>              
              
              <br />
              <b>Install Go</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGo}
              </SyntaxHighlighter>

              <br />
              <b>Install the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallNode}
              </SyntaxHighlighter>

              <br />
              <b>Initialize the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallInitialize}
              </SyntaxHighlighter>              
              
              <br />
              <b>Download Genesis file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallGenesis}
              </SyntaxHighlighter>    
              
              <br />
              <b>Download Addrbook file</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallAddrbook}
              </SyntaxHighlighter>    
              
              <br />
              <b>Add / Update Persistent Peers</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallPeers}
              </SyntaxHighlighter> 
              
              <br />
              <b>Download & decompress Snapshot</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallSnapshot}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Create Service File</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallService}
              </SyntaxHighlighter>     
              
              <br />
              <b>Start the Node</b>
              <SyntaxHighlighter language="shell" style={darcula}>
                {nodeInstallStart}
              </SyntaxHighlighter>                 

            </div>
          )}
        </div>        




        <div
          className="node-box"
          style={{ height: isBelowVisible2 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">03</div>
            <div className="right">Public Endpoints</div>
            <div className="icon" onClick={toggleBelow2}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible2 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible2 && (
            <>
              <div className="below"><a href="https://celestia-testnet-api.synergynodes.com">https://celestia-testnet-api.synergynodes.com</a></div>
              <div className="belowtext">
              <a href="https://celestia-testnet-rpc.synergynodes.com">https://celestia-testnet-rpc.synergynodes.com</a>
              </div>
            </>
          )}
        </div>




        <div
          className="node-box"
          style={{ height: isBelowVisible3 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">04</div>
            <div className="right">Persistent Peers​</div>
            <div className="icon" onClick={toggleBelow3}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible3 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible3 && (
            <>
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={darcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </>
          )}
        </div>


        <div
          className="node-box"
          style={{ height: isBelowVisible4 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">05</div>
            <div className="right">AddrBook File​</div>
            <div className="icon" onClick={toggleBelow4}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible4 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible4 && (
            <>
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </>
          )}
        </div>   
        

        
        <div
          className="node-box"
          style={{ height: isBelowVisible5 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">06</div>
            <div className="right">Genesis File​</div>
            <div className="icon" onClick={toggleBelow5}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible5 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible5 && (
            <>
              <div className="below">
              We recommend you to download the genesis.json file from Celestia official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={darcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </>
          )}
        </div>    
        
        

        <div
          className="node-box"
          style={{ height: isBelowVisible7 ? "100%" : "69px" }}
        >
          <div className="upper">
            <div className="left">07</div>
            <div className="right">IBC Relayers​</div>
            <div className="icon" onClick={toggleBelow7}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                style={{
                  transform: isBelowVisible7 ? "none" : "rotate(180deg)",
                }}
              >
                <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
              </svg>
            </div>
          </div>
          {isBelowVisible7 && (
            <>
              <div className="below">            
                Coming Soon....
              </div>
            </>
          )}
        </div>          


        <div
        className="node-box"
        style={{ height: isBelowVisible9 ? "100%" : "69px" }}
      >
        <div className="upper">
          <div className="left">08</div>
          <div className="right">Auto Compounding​</div>
          <div className="icon" onClick={toggleBelow9}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              style={{
                transform: isBelowVisible9 ? "none" : "rotate(180deg)",
              }}
            >
              <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#838FBE" />
            </svg>
          </div>
        </div>
        {isBelowVisible9 && (
          <>
            <div className="below">
                Coming Soon...
            </div>
          </>
        )}
      </div> 


      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceCelestiatest;
