import "../../styles/Home/Vision.scss";
import vision from "../../images/vision.png";
import vision1 from "../../images/vision1.png";
import vision2 from "../../images/vision2.png";
import vision3 from "../../images/vision3.png";
import { FaChevronRight } from "react-icons/fa6";

const Vision = () => {
  return (
    <div className="vision-back">
      <div className="vision">
        <div className="contents">
          <div className="subtitle">Vision</div>
          <div className="title">Shaping the Future of Synergy Nodes</div>
          <div className="text">
            Our vision is rooted in a deep understanding of the challenges and
            opportunities that lie ahead. We envision a future where describe
            the positive changes or advancements your company aims to bring
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={vision1} className="icon"></img>
            </div>
            <div className="content-panel">
              <div className="title">Empowering Partnerships</div>
              <div className="text">
                Collaboration and partnerships are the pillars of our vision. We
                seek to foster mutually beneficial relationships
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={vision2} className="icon"></img>
            </div>
            <div className="content-panel">
              <div className="title">Global Impact</div>
              <div className="text">
                We envision making a positive and lasting impact on a global
                scale. Our solutions will address critical challenges
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={vision3} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="title">Ethical and Responsible Practices</div>
              <div className="text">
                We are dedicated to conducting business responsibly, upholding
                the highest standards of ethics, and being accountable to our
                stakeholders.
              </div>
            </div>
          </div>
        </div>
        <img src={vision} className="image" />
      </div>
    </div>
  );
};

export default Vision;
