import Header from "./Header";
import ContactIntro from "./Contact/ContactIntro";
import Stats from "./Home/Stats";
import Subscribe from "./Home/Subscribe";
import ContactForm from "./Contact/ContactForm";
import Footer from "./Footer";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactIntro />
      <Stats />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Contact;
