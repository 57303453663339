import "../../styles/Home/Wallet.scss";
import metamask from "../../images/wallet1.png";
import binance from "../../images/wallet2.png";
import formatic from "../../images/wallet3.png";
import autherum from "../../images/wallet4.png";
import coinbase from "../../images/wallet5.png";
import zetaimage from "../../images/test5.png";

const Wallet = () => {
  return (
    <div className="wallet">

    <div className="whatsNew">NEW: <img src={zetaimage} /> <a href="https://zetabridge.synergynodes.com" target="_blank">Zetachain Crosschain Bridge</a></div>

      <div className="title">Our Socials</div>
      <div className="list">
        <a href="https://discord.gg/UEppF4sBdM" target="_blank">
        <div className="item">
          <img src={metamask} className="image"></img>
          <div className="text">Discord</div>
        </div>
        </a>
        <a href="https://t.me/SynergyNodes" target="_blank">
        <div className="item">
          <img src={binance} className="image"></img>
          <div className="text">Telegram</div>
        </div>
        </a>
        <a href="https://twitter.com/SynergyNodes" target="_blank">
        <div className="item">
          <img src={formatic} className="image"></img>
          <div className="text">Twitter</div>
        </div>
        </a>
        <a href="https://www.youtube.com/channel/UCFOFPbgP0wLTQ007NtNl_oQ" target="_blank">
        <div className="item">
          <img src={autherum} className="image"></img>
          <div className="text">Youtube</div>
        </div>
        </a>
        <a href="https://github.com/SynergyNodes" target="_blank">
        <div className="item">
          <img src={coinbase} className="image"></img>
          <div className="text">Github</div>
        </div>
        </a>
      </div>
    </div>
  );
};

export default Wallet;
