export const snapshots = {
'acrechain':
{
	version: 'v1.2.0',
	size: '10.48 GB',
	block: '4116025',
	timestamp: '3',
	file: 'acrechain_4116025.tar.lz4',
	link: 'https://snapshots.synergynodes.com/acrechain_mainnet/acrechain_4116025.tar.lz4',
},
'axelar':
{
	version: 'v0.33.2',
	size: '150.77 GB',
	block: '8781305',
	timestamp: '2.8',
	file: 'axelar_8781305.tar.lz4',
	link: 'https://snapshots.synergynodes.com/axelar_mainnet/axelar_8781305.tar.lz4',
},
'canto':
{
	version: 'v6.0.0',
	size: '34.56 GB',
	block: '5591046',
	timestamp: '2.4',
	file: 'canto_5591046.tar.lz4',
	link: 'https://snapshots.synergynodes.com/canto_mainnet/canto_5591046.tar.lz4',
},
'haqq':
{
	version: 'v1.4.1',
	size: '14.01 GB',
	block: '7215186',
	timestamp: '1.2',
	file: 'haqq_7215186.tar.lz4',
	link: 'https://snapshots.synergynodes.com/haqq_mainnet/haqq_7215186.tar.lz4',
},
'kujira':
{
	version: 'v0.8.7',
	size: '56.3 GB',
	block: '13388421',
	timestamp: '2.2',
	file: 'kujira_13388421.tar.lz4',
	link: 'https://snapshots.synergynodes.com/kujira_mainnet/kujira_13388421.tar.lz4',
},
'mars':
{
	version: 'v1.0.2',
	size: '64.66 GB',
	block: '2968130',
	timestamp: '2',
	file: 'mars_2968130.tar.lz4',
	link: 'https://snapshots.synergynodes.com/mars_mainnet/mars_2968130.tar.lz4',
},
'meme':
{
	version: '1.0.0',
	size: '3.21 GB',
	block: '7251484',
	timestamp: '1.3',
	file: 'meme_7251484.tar.lz4',
	link: 'https://snapshots.synergynodes.com/meme_mainnet/meme_7251484.tar.lz4',
},
'oraichain':
{
	version: 'v0.41.3',
	size: '45 GB',
	block: '13061549',
	timestamp: '1.6',
	file: 'oraichain_13061549.tar.lz4',
	link: 'https://snapshots.synergynodes.com/oraichain/oraichain_13061549.tar.lz4',
},
'planq':
{
	version: 'v1.0.7',
	size: '15.39 GB',
	block: '4518689',
	timestamp: '1.4',
	file: 'planq_4518689.tar.lz4',
	link: 'https://snapshots.synergynodes.com/planq_mainnet/planq_4518689.tar.lz4',
},
'stride':
{
	version: 'v12.0.0',
	size: '6.53 GB',
	block: '5042677',
	timestamp: '1.8',
	file: 'stride_5042677.tar.lz4',
	link: 'https://snapshots.synergynodes.com/stride_mainnet/stride_5042677.tar.lz4',
},
'tenet':
{
	version: 'v11.0.7',
	size: '7.54 GB',
	block: '2955716',
	timestamp: '1.3',
	file: 'tenet_2955716.tar.lz4',
	link: 'https://snapshots.synergynodes.com/tenet_mainnet/tenet_2955716.tar.lz4',
},
'terra_v2':
{
	version: 'v2.4.1',
	size: '21.01 GB',
	block: '6448400',
	timestamp: '1.7',
	file: 'terra_v2_6448400.tar.lz4',
	link: 'https://snapshots.synergynodes.com/terra_v2_mainnet/terra_v2_6448400.tar.lz4',
},
'whitewhale':
{
	version: 'v2.2.6',
	size: '5.14 GB',
	block: '2801679',
	timestamp: '1.6',
	file: 'whitewhale_2801679.tar.lz4',
	link: 'https://snapshots.synergynodes.com/whitewhale_mainnet/whitewhale_2801679.tar.lz4',
},
'elys_testnet':
{
	version: 'v0.8.0',
	size: '1.39 GB',
	block: '2240963',
	timestamp: '24.7',
	file: 'elys_testnet_2240963.tar.lz4',
	link: 'https://snapshots.synergynodes.com/elys_testnet/elys_testnet_2240963.tar.lz4',
},
'haqq_testnet':
{
	version: 'v1.4.1',
	size: '9.34 GB',
	block: '5085514',
	timestamp: '24.7',
	file: 'haqq_testnet_5085514.tar.lz4',
	link: 'https://snapshots.synergynodes.com/haqq_testnet/haqq_testnet_5085514.tar.lz4',
},
'tenet_testnet':
{
	version: 'v11.0.7',
	size: '47.49 GB',
	block: '2974636',
	timestamp: '24.9',
	file: 'tenet_testnet_2974636.tar.lz4',
	link: 'https://snapshots.synergynodes.com/tenet_testnet/tenet_testnet_2974636.tar.lz4',
},
'zetachain_testnet':
{
	version: 'v7.0.0',
	size: '13.8 GB',
	block: '1240141',
	timestamp: '24.6',
	file: 'zetachain_testnet_1240141.tar.lz4',
	link: 'https://snapshots.synergynodes.com/zetachain_testnet/zetachain_testnet_1240141.tar.lz4',
},
}